import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

// Components
import InfoCircleTooltip from "../../custom-widgets/info-circle-tooltip";
import { Form, FormGroup } from "react-bootstrap";
import CDRateCalculatorDisclaimer from "./cd-rate-calculator-disclaimer";

//Styles
import styles from "./certificate-of-deposit.module.scss";
import DecimalInput from "../../inputs/decimal-input";
import { getSetInputValue } from "../../inputs/call-input-function";

const CdRateCalculator = (props) => {
  const SAVING_ACCOUNTS_EARNINGS_PERCENTAGE = 0.001;
  const DEFAULT_INITIAL_DEPOSIT = 1000;
  const DEFAULT_MONTHS_CALCULATOR_PERIOD = 13;
  const DEFAULT_YEARS_CALCULATOR_PERIOD = 1;
  const MONTHS_CALCULATOR_PERIOD_DIGITS_LIMIT = 2;
  const YEARS_CALCULATOR_PERIOD_DIGITS_LIMIT = 1;
  const [chartData, setChartData] = useState([]);
  const [calculatorPeriodType, setCalculatorPeriodType] = useState("months");
  const [chartDotHovered, setChartDotHovered] = useState({});
  const [initialDeposit, setInitialDeposit] = useState(DEFAULT_INITIAL_DEPOSIT);
  const [calculatorPeriod, setCalculatorPeriod] = useState(DEFAULT_MONTHS_CALCULATOR_PERIOD);
  const [APYRate, setAPYRate] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  const THIRTEEN_MO_CD_DATA = props.cdData.find((product) => product.Name === "13-Month CD Special");

  const getAPYRate = () => {
    let APYValue = Math.max(...THIRTEEN_MO_CD_DATA.ProductData.map((apyRate) => parseFloat(apyRate.value)));
    return APYValue > 0 ? APYValue : SAVING_ACCOUNTS_EARNINGS_PERCENTAGE;
  };

  // NOTE: do NOT change this without approval!
  const getTotalBalance = (chartDataValue, lowerDepositValue, depositValue, periodValue, APYRateValue) => {
    if (depositValue > 0) {
      if (periodValue > 0) {
        /* 
        For recursive use we define the initial value for the new array
        If we already have values in chartDataValue we use them, if not
        we define the first one that is the month/year "0" of the chart.
      */
        let chartValues =
          chartDataValue.length > 0
            ? [...chartDataValue]
            : [
                {
                  name: 0,
                  pv: 0, // Principal value must be a number
                  lv: 0, // Lower value must be a number
                  idv: depositValue // Initial deposit value
                }
              ];

        /*
        "periodValue" gets the period typed in years.
        We use ONE as the max value for the period, this is ONE full year of
        APY earned and the rest is in decimals the months earned.
        Like 1.083333333333333 would be 13 months = 1 year and 1 month.
      */
        let prePeriod = periodValue > 1 ? 1 : periodValue;

        /*
        For CD Earnings.
        This will be the changing deposit value to calculate in the entire period.
      */
        let preDepositValue = 0;

        /*
        For Savings Account Earnings.
        This will be the changing deposit value to calculate in the entire period.
      */
        let preLowerDepositValue = 0;

        /*
        For CD Earnings.
        This is the Initial Deposit with its earnings added to continue with the monthly amortization.
      */
        let currentMonthDeposit = depositValue;

        /*
        For Savings Account Earnings.
        This is the Initial Deposit with its earnings added to continue with the monthly amortization.
      */
        let currentLowerMonthDeposit = lowerDepositValue;

        if (calculatorPeriodType === "months") {
          // We initialize the monthsArray empty to just add the new values.
          let monthsArray = [];
          /*
          We convert the prePeriod to months to iterate it, example 1.083333333333333:
          1 to 12 months or 0.083333333333333 to 1 month , depends on the prePeriod value.
        */
          let monthsCount = parseFloat(12 * prePeriod).toFixed(2);

          /*
          For CD Earnings.
          APRValue it is the percentage earned every month to complete the APY value used per year.
          We obtain the APRValue with: the 12th root of our APYRateValue divided by 100 and then
          adding 1, after that, we will do result minus 1, and at the end multiply it by 12.
          NOTE: the 12th root is equal to value at exponential to 1/12 
          APYRateValue = 4
          1.- (Math.pow(0.4 + 1, 1 / 12) - 1) * 12
          2.- (Math.pow(1.4, 1 / 12) - 1) * 12
          3.- (1.0284361557264 - 1) * 12
          4.- 0.0284361557264 * 12
          5.- 0.3412338687168 = APRValue
        */
          let APRValue = (Math.pow(APYRateValue / 100 + 1, 1 / 12) - 1) * 12;

          // For Savings Account Earnings. The same as above APRValue just with different APY
          // let APRLowerValue = (Math.pow(SAVING_ACCOUNTS_EARNINGS_PERCENTAGE / 100 + 1, 1 / 12) - 1) * 12;
          let APRLowerValue = SAVING_ACCOUNTS_EARNINGS_PERCENTAGE;

          /*
          For CD Earnings.
          It is the "initial earning" to start the amortization in a months period.
          We use the last PV value of the actual chart values that we already have in chartValues.
        */
          let currentMonthEarnings = chartValues.length > 0 ? parseFloat(chartValues[chartValues.length - 1].pv) : 0;

          /*
          For Savings Account Earnings.
          It is the "initial earning" to start the amortization in a months period.
          We use the last LV value of the actual chart values that we already have in chartValues.
        */
          let currentLowerMonthEarnings =
            chartValues.length > 0 ? parseFloat(chartValues[chartValues.length - 1].lv) : 0;

          for (let mo = 0; mo < monthsCount; mo++) {
            // For CD Earnings.
            let monthEarnings = (currentMonthDeposit * APRValue) / 12;

            // For Savings Account Earnings.
            let monthLowerEarnings = (currentLowerMonthDeposit * APRLowerValue) / 12;

            // These 2 for CD Earnings.
            currentMonthDeposit += monthEarnings;
            currentMonthEarnings += monthEarnings;

            // These 2 for Savings Account Earnings.
            currentLowerMonthDeposit += monthLowerEarnings;
            currentLowerMonthEarnings += monthLowerEarnings;

            // We push the month value in the array and continue with the loop.
            monthsArray.push({
              name: chartValues.length + mo,
              pv: parseFloat(parseFloat(currentMonthEarnings).toFixed(2)), // Principal value must be a number
              lv: parseFloat(parseFloat(currentLowerMonthEarnings).toFixed(2)), // Lower value must be a number
              idv: depositValue // Initial deposit value
            });
          }

          // We add the months array objects into the chart values array.
          chartValues.push(...monthsArray);
        } else {
          // We just calculate per exact years
          if (prePeriod === 1) {
            /*
            This will be always the same value that is the initial deposit value,
            in the future if we need to calculate something with the first deposit value
            we can use the chartValues[0].idv because the depositValue changes every time
            we call the function.
          */
            let currentYearEarnings = chartValues.length > 0 ? parseFloat(chartValues[0].idv) : depositValue;

            /*
            For CD Earnings.
            This is the Initial Deposit with its earnings added to continue with the yearly amortization.
          */
            preDepositValue = depositValue * (1 + (prePeriod * APYRateValue) / 100);

            /*
            For Savings Account Earnings.
            This is the Initial Deposit with its earnings added to continue with the yearly amortization.
          */
            preLowerDepositValue = lowerDepositValue * (1 + (prePeriod * SAVING_ACCOUNTS_EARNINGS_PERCENTAGE) / 100);

            // We push the year value in the array and continue with the amortization.
            chartValues.push({
              name: chartValues.length,
              pv: parseFloat(parseFloat(preDepositValue - currentYearEarnings).toFixed(2)), // Principal value must be a number
              lv: parseFloat(parseFloat(preLowerDepositValue - currentYearEarnings).toFixed(2)), // Lower value must be a number
              idv: currentYearEarnings // Initial deposit value
            });
          }
        }

        // Here we add the currentValues to the chartData that will render the chart dots
        setChartData(chartValues);

        /*
        We call again the function with the next values left of the period to complete the Total Balance and the Chart Data Values to show
        getTotalBalance(chartDataValue, lowerDepositValue, depositValue, periodValue, APYRateValue)
      */
        return getTotalBalance(
          chartValues,
          calculatorPeriodType === "months" ? currentLowerMonthDeposit : preLowerDepositValue,
          calculatorPeriodType === "months" ? currentMonthDeposit : preDepositValue,
          periodValue - 1,
          APYRateValue
        );
      } else {
        return depositValue;
      }
    } else {
      return 0;
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && label !== 0) {
      return (
        <div className="chart-tooltip p-2 w-auto">
          <p className="mb-0 font-weight-bold text-capitalize">
            {calculatorPeriodType.split("s")[0]} {label}
          </p>
          <p className="mb-0">CD Earnings: {numberFormatter.format(payload[0].value)}</p>
          <p className="mb-0">Savings Account Earnings: {numberFormatter.format(payload[1].value)}</p>
        </div>
      );
    }

    return null;
  };

  const numberFormatter = new Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "currency",
    currency: "USD"
  });

  const doSetChartDotHovered = (dataValue) => {
    setChartDotHovered({
      activeLabel: dataValue.name,
      activePayload: [
        {
          value: dataValue.pv
        },
        {
          value: dataValue.lv
        }
      ]
    });
  };

  const YAxisWidth =
    initialDeposit < 1000
      ? 20
      : initialDeposit < 10000
      ? 30
      : initialDeposit < 100000
      ? 40
      : initialDeposit < 10000000
      ? 55
      : 65;

  useEffect(() => {
    let newValue = getAPYRate();
    getSetInputValue(APYRateRef, {
      newValue: newValue
    });
  }, [THIRTEEN_MO_CD_DATA]);

  useEffect(() => {
    doSetChartDotHovered(
      chartData.length > 0
        ? chartData[chartData.length - 1]
        : {
            name: 0,
            pv: 0,
            lv: 0,
            idv: 0
          }
    );
  }, [chartData]);

  useEffect(() => {
    // console.log("PERIOD SENT:", calculatorPeriod);
    let periodToCalculate = calculatorPeriod ? calculatorPeriod : 0;
    if (periodToCalculate) {
      const balanceValue = getTotalBalance(
        [],
        initialDeposit ? initialDeposit : 0,
        initialDeposit ? initialDeposit : 0,
        calculatorPeriodType === "years" ? periodToCalculate : periodToCalculate / 12,
        APYRate ? APYRate : 0
      );
      // console.log("BALANCE: " + balanceValue + " | " + typeof balanceValue);

      setTotalBalance(balanceValue > 0 ? balanceValue : 0);
    } else {
      setChartData([
        {
          name: 0,
          pv: 0,
          lv: 0,
          idv: initialDeposit
        }
      ]);
      setTotalBalance(initialDeposit);
      // total balance uses the default max digits and decimals
    }
  }, [initialDeposit, calculatorPeriodType, calculatorPeriod, APYRate]);

  const PERCENTAGE_DIGITS_LIMIT = 2;
  const PERCENTAGE_DECIMALS_LIMIT = 3;
  const CURRENCY_DIGITS_LIMIT = 9;
  const CURRENCY_DECIMALS_LIMIT = 2;

  const initialDepositAmountRef = useRef(null);
  const calculatorPeriodRef = useRef(null);
  const APYRateRef = useRef(null);

  const FORM_INPUTS_PROPS = {
    "initial-deposit-amount": {
      id: "initial-deposit-amount",
      fullWidth: true,
      label: "Initial Deposit Amount:",
      LabelEndComponent: () => (
        <InfoCircleTooltip
          id={"initial-deposit-tooltip"}
          trigger={["hover", "focus"]}
          content={"The starting balance for your CD."}
          icon={{
            title: "",
            class: "ml-2 text-gray-60",
            name: "info-circle",
            lib: "far"
          }}
        />
      ),
      name: "initialDepositAmount",
      setNumericValueState: setInitialDeposit,
      initialValue: DEFAULT_INITIAL_DEPOSIT,
      startCharacter: "$",
      maxDigits: CURRENCY_DIGITS_LIMIT,
      maxDecimals: CURRENCY_DECIMALS_LIMIT,
      decimalInputRef: initialDepositAmountRef,
      onChange: (inputEvent) => {
        const inputTarget = inputEvent.target;
        getSetInputValue(initialDepositAmountRef, {
          inputEvent,
          inputTarget,
          newValue: inputTarget.value
        });
      },
      customOnBlur: (event) => {
        if (!parseFloat(event.target.value)) {
          getSetInputValue(initialDepositAmountRef, {
            newValue: DEFAULT_INITIAL_DEPOSIT
          });
        }
      }
    },
    "months-or-years-period": {
      id: "months-or-years-period",
      fullWidth: true,
      label: "Over a Period of:",
      LabelEndComponent: () => (
        <InfoCircleTooltip
          id={"period-tooltip"}
          tabIndex={1}
          trigger={["hover", "focus"]}
          content={"The total number of months or years for this CD to mature."}
          icon={{
            title: "",
            class: "ml-2 text-gray-60",
            name: "info-circle",
            lib: "far"
          }}
        />
      ),
      name: "calculatorPeriod",
      setNumericValueState: setCalculatorPeriod,
      initialValue: DEFAULT_MONTHS_CALCULATOR_PERIOD,
      maxDigits:
        calculatorPeriodType === "months"
          ? MONTHS_CALCULATOR_PERIOD_DIGITS_LIMIT
          : YEARS_CALCULATOR_PERIOD_DIGITS_LIMIT,
      maxDecimals: 0,
      decimalInputRef: calculatorPeriodRef,
      onChange: (inputEvent) => {
        const inputTarget = inputEvent.target;
        getSetInputValue(calculatorPeriodRef, {
          inputEvent,
          inputTarget,
          newValue: inputTarget.value
        });
      },
      customOnBlur: (event) => {
        if (!parseInt(event.target.value)) {
          let newValue =
            calculatorPeriodType === "months" ? DEFAULT_MONTHS_CALCULATOR_PERIOD : DEFAULT_YEARS_CALCULATOR_PERIOD;
          getSetInputValue(calculatorPeriodRef, {
            newValue: newValue
          });
        }
      }
    },
    "apy-rate-percentage": {
      id: "apy-rate-percentage",
      fullWidth: true,
      label: "APY/Interest Rate:",
      LabelEndComponent: () => (
        <InfoCircleTooltip
          id={"apy-tooltip"}
          trigger={["hover", "focus"]}
          content={"ANNUAL PERCENTAGE YIELD (APY) This is the effective annual interest rate earned for this CD."}
          icon={{
            title: "",
            class: "ml-2 text-gray-60",
            name: "info-circle",
            lib: "far"
          }}
        />
      ),
      name: "APYRate",
      setNumericValueState: setAPYRate,
      initialValue: APYRate,
      startCharacter: "%",
      maxDigits: PERCENTAGE_DIGITS_LIMIT,
      maxDecimals: PERCENTAGE_DECIMALS_LIMIT,
      decimalInputRef: APYRateRef,
      onChange: (inputEvent) => {
        const inputTarget = inputEvent.target;
        getSetInputValue(APYRateRef, {
          inputEvent,
          inputTarget,
          newValue: inputTarget.value
        });
      },
      customOnBlur: (event) => {
        let newValue = parseFloat(event.target.value) ? event.target.value : getAPYRate();
        getSetInputValue(APYRateRef, {
          newValue: newValue
        });
      }
    }
  };

  return (
    <section id="cd-rates-calculator" className="container py-0">
      <div className="border border-radius-12 p-3 mb-4">
        <h2 className="text-success">WaFd CD Savings Calculator</h2>
        <p>
          Use the Savings Calculator to see out how much interest is earned on a certificate of deposit (CD). Enter your
          information below to compare CD and basic savings account rates.
        </p>

        <div className="row">
          <div className="col-md-5 col-lg-4">
            <DecimalInput {...FORM_INPUTS_PROPS["initial-deposit-amount"]} />

            <div className="row align-items-end">
              <div className="col-5 pr-0" style={{ maxWidth: "46%", flex: "0 0 46%" }}>
                <DecimalInput {...FORM_INPUTS_PROPS["months-or-years-period"]} />
              </div>
              <div className="col px-0">
                <FormGroup className="row ml-2 mb-3" style={{ marginRight: "-10px" }}>
                  <Form.Check
                    inline
                    label="Months"
                    name="months"
                    type={"radio"}
                    id={`inline-radio-1`}
                    value={"months"}
                    checked={calculatorPeriodType === "months" ? true : false}
                    onChange={() => {
                      setCalculatorPeriodType("months");
                      getSetInputValue(calculatorPeriodRef, {
                        newValue: DEFAULT_MONTHS_CALCULATOR_PERIOD
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label="Years"
                    name="years"
                    type={"radio"}
                    id={`inline-radio-2`}
                    className="mt-0 mr-0"
                    value={"years"}
                    checked={calculatorPeriodType === "years" ? true : false}
                    onChange={() => {
                      setCalculatorPeriodType("years");
                      getSetInputValue(calculatorPeriodRef, {
                        newValue: DEFAULT_YEARS_CALCULATOR_PERIOD
                      });
                    }}
                  />
                </FormGroup>
              </div>
            </div>

            <DecimalInput {...FORM_INPUTS_PROPS["apy-rate-percentage"]} />

            <div id="cd-calculator-total-balance" className="display-5-font-size mt-4 mb-2 text-green-60">
              {numberFormatter.format(totalBalance)}
            </div>
            <p id="cd-calculator-total-balance-period-info" className="font-weight-bold mb-0 text-gray-90">
              Your total CD balance at the end of {calculatorPeriod ? calculatorPeriod : 0} {calculatorPeriodType}
            </p>

            <div className="mt-3 mb-5">
              <Link to="/open-an-account" className="btn btn-primary w-100" id="cd-calculator-open-account">
                Open an Account
              </Link>
            </div>
          </div>
          <div className="col-md-7 col-lg-8 position-relative">
            <p className="mb-2 font-italic">
              Interest earned by {calculatorPeriodType === "months" ? "Month" : "Year"}{" "}
              {calculatorPeriod ? chartDotHovered.activeLabel : 0}:
            </p>
            <div className="row align-items-center mb-2 mx-0">
              <div className={styles.circleMini} />
              <p className="mb-0">
                &nbsp;&nbsp;CD Earnings:{" "}
                <span id="cd-earnings">
                  {numberFormatter.format(
                    chartDotHovered.activePayload != null
                      ? calculatorPeriod
                        ? chartDotHovered.activePayload[0].value
                        : 0
                      : ""
                  )}
                </span>
              </p>
            </div>
            <div className="row align-items-center mb-2 mx-0">
              <div
                className={styles.circleMini}
                style={{
                  backgroundColor: "#FFBB29"
                }}
              />

              <p className="mb-0">
                &nbsp;&nbsp;Savings Account Earnings ({SAVING_ACCOUNTS_EARNINGS_PERCENTAGE * 100}%):{" "}
                <span id="savings-account-earnings">
                  {numberFormatter.format(
                    chartDotHovered.activePayload != null
                      ? calculatorPeriod
                        ? chartDotHovered.activePayload[1].value
                        : 0
                      : ""
                  )}
                </span>
              </p>
            </div>
            <p className="mb-3 font-weight-bold">
              Total Interest Earned:{" "}
              <span id="total-interest-earned">{numberFormatter.format(totalBalance - initialDeposit)}</span>
            </p>
            <p
              className={`d-none d-md-block text-sm mb-0 ${styles.periodTypeLabel}`}
              style={{
                left: -5
              }}
            >
              {calculatorPeriodType}
            </p>
            <p className={`d-block d-md-none text-sm mb-0 ${styles.periodTypeLabel}`}>
              {calculatorPeriodType === "months" ? "Mos." : "Yrs."}
            </p>
            <ResponsiveContainer width="100%" height={380}>
              <LineChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
                onMouseMove={(e) => {
                  if (e.isTooltipActive && e?.activeLabel !== 0) {
                    doSetChartDotHovered(
                      e.activePayload.length > 0
                        ? {
                            name: e.activeLabel,
                            pv: e.activePayload[0].payload.pv,
                            lv: e.activePayload[0].payload.lv,
                            idv: e.activePayload[0].payload.idv
                          }
                        : {
                            name: 0,
                            pv: 0,
                            lv: 0,
                            idv: 0
                          }
                    );
                  }
                }}
                onMouseLeave={() => {
                  doSetChartDotHovered(
                    chartData.length > 0
                      ? chartData[chartData.length - 1]
                      : {
                          name: 0,
                          pv: 0,
                          lv: 0,
                          idv: 0
                        }
                  );
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis width={YAxisWidth} />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  name="CD Earnings"
                  type="monotone"
                  dataKey="pv"
                  stroke="#008733"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                />
                <Line
                  name="Saving Account Earnings"
                  type="monotone"
                  dataKey="lv"
                  stroke="#FFBB29"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <CDRateCalculatorDisclaimer />
    </section>
  );
};

export default CdRateCalculator;

