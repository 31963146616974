import React from "react";
import { Link } from "gatsby";

const CdCard = (props) => {
  const { id, title, btn, cardData, className } = props;
  const TitleCard = ({ text, symbol }) => {
    return (
      <h2>
        <span className="text-success">{text}</span>
        {symbol}
      </h2>
    );
  };

  // A <p> with text so it can have spacing
  const CardSection = ({ id, className = "", children }) => {
    return (
      <p className={className} id={id}>
        {children}
      </p>
    );
  };

  const FooterCard = ({ url, id, text }) => {
    return (
      <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
        <Link to={url} className="btn btn-primary btn-block mb-1" id={id}>
          {text}
        </Link>
      </div>
    );
  };

  return (
    <div className={`${className} mb-4 card border-0`} id={id}>
      <div className="bg-light card-body border-radius-top-12">
        <TitleCard {...title} />
        {cardData.map((section) => {
          const { text, customRender, ...sectionProps } = section;

          if (!customRender) {
            return <CardSection {...sectionProps}>{text}</CardSection>;
          }

          return <CardSection {...sectionProps}>{customRender}</CardSection>;
        })}
      </div>
      <FooterCard {...btn} />
    </div>
  );
};

export default CdCard;

CdCard.defaultProps = {
  id: "",
  className: "col-md-6",
  // Symbol is a string added to the end of the title, usually it is a * or **.
  title: { text: "", symbol: null },
  btn: { url: "", id: "", text: "" },
  // Card Data is an array of section objects.
  // A Card Section is just a <p> with a line of text (p has its own spacing).
  // If you want more than one line of text or special classes in between the text, use customRender property.
  // CustomRender accepts a react object instead of a string to be pass as a child of the <p>
  cardData: [
    { text: "", className: "", id: "" },
    { className: "", id: "", customRender: null }
  ]
};
