import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./decimal-input.module.scss";

/**
 * This is a Decimal Input that can handle max Digits and Decimals as we want, adding a proper format to the typed number.
 *
 * @param {Object} params - The object containing props.
 * @param {number} params.id - The input ID like "decimal-input".
 * @param {string} params.label - Text for the label if we want to add one to the top of the input.
 * @param {string} params.name - Name of the input to handle in different logic, we can use lower upper case for the id like "decimalInput".
 * @param {string} params.startCharacter - If defined we add the desired character at the beginning.
 * @param {string} params.endCharacter - If defined we add the desired character at the end.
 * @param {string} params.textAlign - Wether the input text is aligned to the right or left.
 * @param {boolean} params.disabled - Boolean to disable the input.
 * @param {number} params.maxDigits - Digits before the decimal point limit without including the ",".
 * @param {number} params.maxDecimals - Digits after the decimal point limit.
 * @param {number} params.initialValue - If we have a defined initial value to show we can se it in here.
 * @param {Object} params.decimalInputRef - The ref to access the component functions.
 * @param {Object} params.setNumericValueState - The function to update the value in the parent component.
 * @param {function} params.onChange - We can use the input target event to do some custom logic and use the input value somewhere else.
 * @param {function} params.customOnBlur - If we need to add a custom logic when we blur the input we can use this function.
 * @param {string} params.errors - Error text to show below the input.
 * @param {string} params.errorsLTV - LTV Error text to show below the input.
 * @returns {React.JSX.Element} Returns the JSX component to render
 */
const DecimalInput = (props) => {
  const [focus, setFocus] = useState(false);
  const [formattedValueState, setFormattedValueState] = useState("");
  const [errorsState, setErrorsState] = useState("");
  const [initialized, setInitialized] = useState(false);

  const inputRef = useRef(null);

  const {
    startCharacter,
    endCharacter,
    textAlign,
    customOnBlur,
    maxDigits,
    maxDecimals,
    initialValue,
    decimalInputRef,
    setNumericValueState,
    EndCustomComponent,
    ...remainingProps
  } = props;

  const InputContainer = ({ id, fullWidth, label, secondaryLabel, LabelStartComponent, LabelEndComponent, component }) => {
    return (
      <div id={"decimal-input-container-" + id} className={fullWidth ? "" : "col-md-6"} style={{ zIndex: 2 }}>
        <div className="form-group">
          <div className="row mx-0 justify-content-between">
            {label && (
              <label htmlFor={id} className="text-black">
                {LabelStartComponent && <LabelStartComponent />}
                {label}
                {LabelEndComponent && <LabelEndComponent />}
              </label>
            )}

            {secondaryLabel && (
              <label htmlFor={id} className={styles.secondaryLabel}>
                {secondaryLabel}
              </label>
            )}
          </div>
          {component}
        </div>
        {/* {errorsLTVState && <span className="form-text text-danger mt-n3 mb-3">{errorsLTVState}</span>} */}
        {errorsState && <span className="form-text text-danger mt-n3 mb-3">{errorsState}</span>}
        {/* <span className="form-text text-danger mt-n3 mb-3">This little error</span> */}
      </div>
    );
  };

  const formatCurrency = (inputValue) => {
    // Remove non-numeric characters except the decimal point
    let cleanedValue = (inputValue + "").replace(/[^\d.]/g, "");

    // Split the cleaned string into parts based on the decimal point
    let parts = cleanedValue.split(".");

    // Add thousands separators to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Rejoin the parts with a decimal point
    let formattedValue = parts.join(".");

    return formattedValue;
  };

  const formatValue = (inputValue, maxDigitsProp = 9, maxDecimalsProp = 2) => {
    // Remove non-numeric characters except the decimal point
    let cleanedValue = (inputValue + "").replace(/[^\d.]/g, "");

    let newCleanedValue = maxDecimalsProp ? cleanedValue : cleanedValue.replace(".", "");
    // Split the cleaned string into parts based on the dot
    let parts = newCleanedValue.split(".");

    // Ensure there's at least one zero before the decimal point
    if (parts[0] === "" && parts.length > 1) {
      parts[0] = "0";
    }

    // Remove leading 0 if you type for the first time
    if (parts[0].length === 2 && parts[0][0] === "0") {
      parts[0] = parts[0].slice(1);
    }

    let oldNumericValue = formattedValueState.replaceAll(",", "");
    // Prevent entering multiple decimal points
    if (parts.length > 2) {
      let backupParts = (oldNumericValue + "").split(".");
      parts = backupParts;
    }

    // Limit the digits part to the specified maximum digits
    if (parts[0].length > maxDigitsProp) {
      parts[0] = (oldNumericValue + "").split(".")[0];
    }

    // Limit the decimal part to the specified maximum decimals
    if (parts[1]) {
      // In case the new value have more than the max decimals we will validate the change.
      if (parts[1].length > maxDecimalsProp) {
        let newDecimalsToCompare = parts[1].substring(0, maxDecimalsProp);
        let oldDecimalsToCompare = (oldNumericValue + "").split(".")[1];

        // We have to check if we had values in the decimals area before, if we already had 2 decimals
        // we won't allow to enter more text and we will keep the old value as it is.
        if (oldDecimalsToCompare == null || oldDecimalsToCompare?.length < 2) {
          parts[1] = newDecimalsToCompare;
        } else {
          parts[1] = oldDecimalsToCompare;
        }
      }
    }

    // Join the parts back together with a dot
    const formattedValue = parts.join(".");

    // Parse the numeric value and store it in state
    const numericValue = formattedValue.includes(".") ? parseFloat(formattedValue) : parseInt(formattedValue);

    // Format the value as currency and store it in state
    const currencyValue = formatCurrency(formattedValue);

    return { numericValue, currencyValue };
  };

  const getSetInputValue = (event, inputTarget, inputValue) => {
    if (inputValue != null) {
      const { numericValue, currencyValue } = formatValue(inputValue, maxDigits, maxDecimals);

      if (inputTarget) {
        const { selectionStart } = inputTarget;

        setNumericValueState(numericValue);

        setFormattedValueState(currencyValue);

        // Calculate the new cursor position based on formatting changes
        let newPosition = selectionStart + (currencyValue.length - inputValue.length);

        // Adjust cursor position if typing right after a comma
        const commaIndex = currencyValue.indexOf(",");
        if (commaIndex !== -1 && selectionStart === commaIndex + 1 && /^\d$/.test(inputValue)) {
          newPosition--;
        }

        // If backspace key was pressed
        if (event && event.nativeEvent.inputType === "deleteContentBackward") {
          // If we are at the right of the "," and pressing backspace we will move the cursor to the left of the ","
          if (selectionStart === 0 || currencyValue.charAt(selectionStart) === ",") {
            newPosition = Math.max(0, selectionStart);
          } else {
            // We adjust the newPosition to be the right cursor position, if not we will have a plus 1 number, it doesn't affect
            // as if you use a selectionRange bigger than the current maximum it will move you to the maximum possible position,
            // but we have to adjust to the right number in case we need an accurate cursor position value.

            // And we won't adjust if we are typing with decimals
            if (currencyValue.length === 3 && !currencyValue.includes("."))
              newPosition = Math.max(0, selectionStart - 1);
          }
        }

        // console.log("NUMERIC VALUE:", numericValue);
        // console.log("FORMATTED VALUE:", currencyValue);
        // console.log("CURSOR POSITION:", newPosition);
        // console.log("SELECTION START:", selectionStart);

        // Restore cursor position
        requestAnimationFrame(() => {
          inputRef.current?.setSelectionRange(newPosition, newPosition);
        });
      } else {
        setNumericValueState(numericValue);

        setFormattedValueState(currencyValue);
      }

      return numericValue;
    } else {
      const { numericValue } = formatValue(formattedValueState, maxDigits, maxDecimals);
      return numericValue;
    }
  };

  const getSetError = (newValue) => {
    setErrorsState(newValue);
    return newValue;
  };

  const handleFocus = (event) => {
    const input = event.target;
    input.select();
  };

  useEffect(() => {
    // console.log("DECIMAL INPUT REF:", decimalInputRef);
    // console.log("INITIAL VALUE:", initialValue);
    if (!isNaN(initialValue) && !initialized) {
      const { numericValue, currencyValue } = formatValue(initialValue, maxDigits, maxDecimals);
      // console.log("NUMERIC VALUE:", numericValue);
      // console.log("FORMATTED VALUE:", currencyValue);
      setFormattedValueState(currencyValue);
      setNumericValueState(numericValue);
      // console.log("Initialized");
      setInitialized(true);
    }
  }, []);

  // Assigning the ref to the decimalInputRef prop
  React.useImperativeHandle(decimalInputRef, () => ({
    getSetInputValue,
    getSetError
  }));

  return (
    <InputContainer
      id={props.id}
      fullWidth={props.fullWidth}
      label={props.label}
      secondaryLabel={props.secondaryLabel}
      LabelStartComponent={props.LabelStartComponent}
      LabelEndComponent={props.LabelEndComponent}
      component={
        <div className={`form-row`}>
          <div className="col">
            <div className="row mx-0">
              {startCharacter && (
                <span className={`${styles.inputPrepend} position-absolute pl-2`}>{startCharacter}</span>
              )}
              {endCharacter && <span className={`${styles.inputAppend} position-absolute pr-2`}>{endCharacter}</span>}
              <input
                className={
                  "form-control border-radius-6 text-black " +
                  (textAlign === "left"
                    ? startCharacter
                      ? "pl-4"
                      : ""
                    : endCharacter
                    ? styles.rightAlignedInput + " pr-4"
                    : styles.rightAlignedInput)
                }
                type="text"
                inputMode="decimal"
                value={formattedValueState}
                autoFocus={focus}
                onFocus={(event) => {
                  setFocus(true);
                  handleFocus(event);
                }}
                onBlur={(event) => {
                  if (customOnBlur) {
                    customOnBlur(event);
                  }
                  setFocus(false);
                }}
                ref={inputRef}
                {...remainingProps}
              />
              {EndCustomComponent && <EndCustomComponent />}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default React.memo(DecimalInput, () => true);

DecimalInput.defaultProps = {
  id: "decimal-input",
  fullWidth: false,
  label: "Decimal Input",
  secondaryLabel: "",
  LabelStartComponent: null,
  LabelEndComponent: null,
  name: "decimalInput",
  startCharacter: "",
  endCharacter: "",
  textAlign: "left",
  disabled: false,
  maxDigits: 9,
  maxDecimals: 2,
  initialValue: "0",
  decimalInputRef: {},
  setNumericValueState: () => {},
  onChange: () => {},
  customOnBlur: () => {}
};

DecimalInput.propTypes = {
  id: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
  LabelStartComponent: PropTypes.element,
  LabelEndComponent: PropTypes.element,
  startCharacter: PropTypes.string,
  endCharacter: PropTypes.string,
  textAlign: PropTypes.oneOf(["left", "right"]),
  disabled: PropTypes.bool,
  maxDigits: PropTypes.number,
  maxDecimals: PropTypes.number,
  initialValue: PropTypes.number,
  decimalInputRef: PropTypes.object,
  setNumericValueState: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  customOnBlur: PropTypes.func
};
