import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import CdCard from "../card/cd-card";
import getProductDataByName from "../../helpers/getProductDataByName";
import { useLanguageContext } from "../../contexts/language-context";

const CdCards = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiProducts {
        nodes {
          Name
          ProductData {
            name
            value
          }
        }
      }
    }
  `);

  const getProductValue = (productName, productDataName) => {
    return (
      getProductDataByName(data.allStrapiProducts, productName, productDataName)?.value.replace(/Plus/, "Más") ?? "N/A"
    );
  };

  const englishCds = [
    {
      id: "four-to-five-month-cd-special",
      title: { text: "4-5 Month CD Special", symbol: null },
      btn: { url: "/locations", id: "4-5-month-cd-special-cta", text: "Open in Branch" },
      cardData: [
        {
          text: "$1,000 minimum to open ($500 minimum to open for minors)"
        },
        {
          text: "A penalty may be assessed for early withdrawal.",
          id: "4-5-month-penalty"
        },
        {
          id: "4-5-month-with-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
              </div>
              <div>Interest Rate/Annual Percentage Yield</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="4-5-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("4-5-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="4-5-month-cd-with-eligible-checking-apy">
                  {getProductValue("4-5-Month CD Special", "APY - Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          id: "4-5-month-with-out-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Interest Rate/APY Without Eligible<sup>2</sup> Checking:
              </div>
              <div>Not Available</div>
            </>
          )
        },
        {
          text: "Renews to 4-5 Month CD",
          id: "4-5-month-renewal-term"
        }
      ]
    },
    {
      id: "seven-month-cd-special",
      title: { text: "7 Month CD Special", symbol: null },
      btn: { url: "/open-an-account", id: "7-month-cd-special-cta", text: "Open an Account" },
      cardData: [
        {
          text: "$1,000 minimum to open ($500 minimum to open for minors)"
        },
        {
          text: "A penalty may be assessed for early withdrawal.",
          id: "7-month-penalty"
        },
        {
          id: "7-month-with-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
              </div>
              <div>Interest Rate/Annual Percentage Yield</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="7-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("7-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="7-month-cd-with-eligible-checking-apy">
                  {getProductValue("7-Month CD Special", "APY - Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          id: "7-month-with-out-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Interest Rate/APY Without Eligible<sup>2</sup> Checking:
              </div>
              <div>Interest Rate/Annual Percentage Yield</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="7-month-cd-without-eligible-checking-interest-rate">
                  {getProductValue("7-Month CD Special", "Interest Rate - Not Eligible Checking")}
                </span>
                /
                <span id="7-month-cd-without-eligible-checking-apy">
                  {getProductValue("7-Month CD Special", "APY - Not Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          text: "Renews to 6-11 Month CD",
          id: "7-month-renewal-term"
        }
      ]
    },
    {
      id: "thirteen-month-cd-special",
      title: { text: "13 Month CD Special", symbol: null },
      btn: { url: "/open-an-account", id: "13-month-cd-special-cta", text: "Open an Account" },
      cardData: [
        {
          text: "$1,000 minimum to open ($500 minimum to open for minors)"
        },
        {
          text: "A penalty may be assessed for early withdrawal.",
          id: "13-month-penalty"
        },
        {
          id: "13-month-with-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
              </div>
              <div>Interest Rate/Annual Percentage Yield</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="13-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("13-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="13-month-cd-with-eligible-checking-apy">
                  {getProductValue("13-Month CD Special", "APY - Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          id: "13-month-with-out-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Interest Rate/APY Without Eligible<sup>2</sup> Checking:
              </div>
              <div>Interest Rate/Annual Percentage Yield</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="13-month-cd-without-eligible-checking-interest-rate">
                  {getProductValue("13-Month CD Special", "Interest Rate - Not Eligible Checking")}
                </span>
                /
                <span id="13-month-cd-without-eligible-checking-apy">
                  {getProductValue("13-Month CD Special", "APY - Not Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          text: "Renews to 12-17 Month CD",
          id: "13-month-renewal-term"
        }
      ]
    },
    {
      id: "nineteen-month-cd-special",
      title: { text: "19 Month CD Special", symbol: null },
      btn: { url: "/open-an-account", id: "19-month-cd-special-cta", text: "Open an Account" },
      cardData: [
        {
          text: "$1,000 minimum to open ($500 minimum to open for minors)"
        },
        {
          text: "A penalty may be assessed for early withdrawal.",
          id: "19-month-penalty"
        },
        {
          id: "19-month-with-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
              </div>
              <div>Interest Rate/Annual Percentage Yield</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="19-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("19-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="19-month-cd-with-eligible-checking-apy">
                  {getProductValue("19-Month CD Special", "APY - Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          id: "19-month-with-out-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Interest Rate/APY Without Eligible<sup>2</sup> Checking:
              </div>
              <div>Interest Rate/Annual Percentage Yield</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="19-month-cd-without-eligible-checking-interest-rate">
                  {getProductValue("19-Month CD Special", "Interest Rate - Not Eligible Checking")}
                </span>
                /
                <span id="19-month-cd-without-eligible-checking-apy">
                  {getProductValue("19-Month CD Special", "APY - Not Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          text: "Renews to 18-23 Month CD",
          id: "19-month-renewal-term"
        }
      ]
    }
  ];

  const spanishCds = [
    {
      id: "four-to-five-month-cd-special",
      title: { text: "Especial de 4-5 meses para Cuenta a Plazo Fijo (CD)", symbol: null },
      btn: { url: "/es/sucursales", id: "4-5-month-cd-special-cta", text: "Abrir en sucursal" },
      cardData: [
        {
          text: "$1,000 mínimo para abrir ($500 mínimo para menores)."
        },
        {
          text: "Pueden aplicarse multas por retiros antes del vencimiento establecido.",
          id: "4-5-month-penalty"
        },
        {
          id: "4-5-month-with-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Con cuenta de cheques elegible<sup>&dagger;</sup> o $25k dinero nuevo:
              </div>
              <div>Tasa de interés/APY</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="4-5-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("4-5-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="4-5-month-cd-with-eligible-checking-apy">
                  {getProductValue("4-5-Month CD Special", "APY - Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          id: "4-5-month-with-out-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Sin cuenta de cheques elegible<sup>&dagger;</sup>:
              </div>
              <div>No Disponible</div>
            </>
          )
        },
        {
          text: "Renueva a 4-5 meses",
          id: "4-5-month-renewal-term"
        }
      ]
    },
    {
      id: "seven-month-cd-special",
      title: { text: "Especial de 7 meses para Cuenta a Plazo Fijo (CD)", symbol: null },
      btn: { url: "/open-an-account", id: "7-month-cd-special-cta", text: "Abrir una cuenta" },
      cardData: [
        {
          text: "$1,000 mínimo para abrir ($500 mínimo para menores)."
        },
        {
          text: "Pueden aplicarse multas por retiros antes del vencimiento establecido.",
          id: "7-month-penalty"
        },
        {
          id: "7-month-with-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Con cuenta de cheques elegible<sup>&dagger;</sup> o $25k dinero nuevo:
              </div>
              <div>Tasa de interés/APY</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="7-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("7-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="7-month-cd-with-eligible-checking-apy">
                  {getProductValue("7-Month CD Special", "APY - Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          id: "7-month-with-out-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Sin cuenta de cheques elegible<sup>&dagger;</sup>:
              </div>
              <div>Tasa de interés/APY</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="7-month-cd-without-eligible-checking-interest-rate">
                  {getProductValue("7-Month CD Special", "Interest Rate - Not Eligible Checking")}
                </span>
                /
                <span id="7-month-cd-without-eligible-checking-apy">
                  {getProductValue("7-Month CD Special", "APY - Not Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          text: "Renueva a 6-11 meses",
          id: "7-month-renewal-term"
        }
      ]
    },
    {
      id: "thirteen-month-cd-special",
      title: { text: "Especial de 13 meses para Cuenta a Plazo Fijo (CD)", symbol: null },
      btn: { url: "/open-an-account", id: "13-month-cd-special-cta", text: "Abrir una cuenta" },
      cardData: [
        {
          text: "$1,000 mínimo para abrir ($500 mínimo para menores)."
        },
        {
          text: "Pueden aplicarse multas por retiros antes del vencimiento establecido.",
          id: "13-month-penalty"
        },
        {
          id: "13-month-with-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Con cuenta de cheques elegible<sup>&dagger;</sup> o $25k dinero nuevo:
              </div>
              <div>Tasa de interés/APY</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="13-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("13-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="13-month-cd-with-eligible-checking-apy">
                  {getProductValue("13-Month CD Special", "APY - Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          id: "13-month-with-out-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Sin cuenta de cheques elegible<sup>&dagger;</sup>:
              </div>
              <div>Tasa de interés/APY</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="13-month-cd-without-eligible-checking-interest-rate">
                  {getProductValue("13-Month CD Special", "Interest Rate - Not Eligible Checking")}
                </span>
                /
                <span id="13-month-cd-without-eligible-checking-apy">
                  {getProductValue("13-Month CD Special", "APY - Not Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          text: "Renueva a 12-17 meses",
          id: "13-month-renewal-term"
        }
      ]
    },
    {
      id: "nineteen-month-cd-special",
      title: { text: "Especial de 19 meses para Cuenta a Plazo Fijo (CD)", symbol: null },
      btn: { url: "/open-an-account", id: "19-month-cd-special-cta", text: "Abrir una cuenta" },
      cardData: [
        {
          text: "$1,000 mínimo para abrir ($500 mínimo para menores)."
        },
        {
          text: "Pueden aplicarse multas por retiros antes del vencimiento establecido.",
          id: "19-month-penalty"
        },
        {
          id: "19-month-with-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Con cuenta de cheques elegible<sup>&dagger;</sup> o $25k dinero nuevo:
              </div>
              <div>Tasa de interés/APY</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="19-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("19-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="19-month-cd-with-eligible-checking-apy">
                  {getProductValue("19-Month CD Special", "APY - Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          id: "19-month-with-out-eligible-checking",
          customRender: (
            <>
              <div className="font-weight-bold">
                Sin cuenta de cheques elegible<sup>&dagger;</sup>:
              </div>
              <div>Tasa de interés/APY</div>
              <h4 className="text-success font-weight-semi-bold">
                <span id="19-month-cd-without-eligible-checking-interest-rate">
                  {getProductValue("19-Month CD Special", "Interest Rate - Not Eligible Checking")}
                </span>
                /
                <span id="19-month-cd-without-eligible-checking-apy">
                  {getProductValue("19-Month CD Special", "APY - Not Eligible Checking")}
                </span>
              </h4>
            </>
          )
        },
        {
          text: "Renueva a 18-23 meses",
          id: "19-month-renewal-term"
        }
      ]
    }
  ];
  const isSpanish = useLanguageContext();
  const cardsData = isSpanish ? spanishCds : englishCds;

  return (
    <>
      {cardsData.map((card) => {
        return <CdCard className={className} {...card} />;
      })}
    </>
  );
};

export default CdCards;

CdCards.defaultProps = {
  className: "col-md-6"
};
